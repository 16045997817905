<template>
  <div class="the-home">
    <header class="header">
      <a href="https://inflowaccount.co.th/">
        <img :src="require('@/assets/new_logo-1.png')" alt="Logo" class="logo" />
      </a>
      <h1></h1>
    </header>
    <div class="container" style="margin-top: 100px;">
      <b-row>
        <b-col class="text-center" cols="12">
          <h1>แบบประเมิน</h1>
          <h2 :class="maincolor">กิจการควรจดทะเบียนเพิ่มสาขาหรือไม่</h2>
          <img class="img img-desktop img-mobile" src="../assets/head-line.png" alt="Store Images"/>
        </b-col>
      </b-row>
      <b-row>
        <ValidationObserver v-slot="{ handleSubmit, valid }">
          <b-col>
            <TheAssessmentFormCard :questions="questions" />
          </b-col>
          <b-col class="text-center padding" cols="12">
            <b-button
              variant="warning"
              @click="handleSubmit(submit)"
              type="submit"
              :class="{ 'btn-disabled': !valid }"
            >
              <img
                class="img"
                src="../assets/icon-view.png"
                alt="Store Images"
                height="25px"
                width="30px"
              />
              ประมวลผล </b-button
            >&nbsp;&nbsp;&nbsp;
            <b-button variant="secondary" @click="refreshComponent"
              >ล้างข้อมูล</b-button
            >
          </b-col>
        </ValidationObserver>
      </b-row>
      <b-row> </b-row>
    </div>
  </div>
</template>
<script>
import TheAssessmentFormCard from "./TheAssessmentFormCard.vue";
// import TheUnderLineImage from "./TheUnderLineImage.vue";
// import { required, minLength } from 'vuelidate/lib/validators'
export default {
  name: "TheHome",
  components: {
    TheAssessmentFormCard,
    // TheUnderLineImage,
  },
  data() {
    return {
      questions: [
        {
          title:
            "1.กิจการของคุณ “ก่อสร้างโรงงานถาวรเพื่อใช้ผลิตสินค้าของตนเองโดยเฉพาะ”",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title: "2.กิจการของคุณ “ก่อสร้างโกดังถาวรเพื่อเก็บสินค้าของตนเอง”",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "3.กิจการของคุณ “มีหน้าร้านถาวรเป็นของตนเองแยกออกมาจากสำนักงานใหญ่”",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "4.กิจการของคุณ “จัดบู๊ธขายสินค้าหรือแนะนำสินค้าตามงานแสดงสินค้าต่างๆ”",
          isTrue: null,
          ishighRisk: true,
        },
        {
          title:
            "5.กิจการของคุณ “จ้างบริษัทอื่นผลิตสินค้าหรือวัสดุอุปกรณ์ให้ โดยไม่มีพนักงานของกิจการไปปฏิบัติงาน”",
          isTrue: null,
          ishighRisk: true,
        },
      ],
      maincolor: "maincolor",
      warning: "warning",
    };
  },
  methods: {
    submit() {
      const hasYesAnswer = this.questions
        .slice(0, 3)
        .some((question) => question.isTrue);
      const allQuestionsAnswered = this.questions.every(
        (question) => question.isTrue !== null
      );

      if (!allQuestionsAnswered) {
        alert("กรุณาตอบคำถามทั้งหมด");
        return;
      }

      if (hasYesAnswer) {
        this.$router.push("/add-branch").catch(() => {});
      } else {
        this.$router.push("/no-branch").catch(() => {});
      }
    },

    refreshComponent() {
      this.questions.forEach((question) => {
        question.isTrue = null;
      });
    },
    isDisabled() {
      return false;
    },
    calculateRisk() {
      this.questions.some((question) => {
        console.log(question.title);
        if (question.isTrue && question.ishighRisk) {
          this.$router.push("/AddBranch").catch(() => {});
          return true;
        } else {
          this.$router.push("/NoBranch").catch(() => {});
          return false;
        }
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .header {
    background-color: rgb(0 0 0 / 79%);
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  .logo {
    width: auto; 
    height: auto;
  }

  
  .main-content {
    margin-top: 70px; 
  }
.row {
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-disabled {
  background-color: gray;
  color: white;
}

.maincolor {
  color: #ea6b0d;
}
.warning {
  color: #ea6b0d;
}

.padding {
  padding-bottom: 20px;
  padding-top: 20px;
}
.btn-warning {
  color: #ffffff;
  background-color: #ea6b0d;
  border-color: #ea6b0d;
}

.btn-warning:hover {
  color: #ea6b0d;
  background-color: #ffffff;
  border-color: #ea6b0d;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #ea6b0d;
  border-color: #ea6b0d;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #ffffff;
  background-color: #ea6b0d;
  border-color: #ea6b0d;
  box-shadow: 0 0 0 0.2rem rgba(239, 166, 20, 0.5);
}
.btn-secondary:hover {
  color: #5a6268;
  background-color: #ffffff;
  border-color: #545b62;
}
a:hover {
  color: #0056b3;
  text-decoration: none;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ea6b0d !important;
  background-color: #ea6b0d !important;
}
.img-desktop{
  padding-bottom: 30px; 
  width: 60%;
}
.header {
    background-color: rgb(0 0 0 / 79%);
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  .logo {
    width: auto; 
    height: auto;
  }

  
  .main-content {
    margin-top: 70px; 
  }

@media (max-width: 768px) {
  .img-mobile{
  padding-bottom: 30px; 
  width: 100%;
}

}

</style>
