<template>
  <div class="the-home">
        <header class="header">
      <a href="https://inflowaccount.co.th/">
        <img :src="require('@/assets/new_logo-1.png')" alt="Logo" class="logo" />
      </a>
      <h1></h1>
    </header>
    <div class="container" style="margin-top: 100px;">
      <b-row>
        <b-col class="text-center" cols="12">
          <h1>ผลประเมินของคุณ!!</h1>
          <img class="img img-desktop img-mobile" src="../assets/head-line.png" alt="Store Images"/>
      </b-col>
      </b-row>

      <b-card
        style="
          box-shadow: 1px 1px 12px 5px darkgrey;
          border: 0px solid rgba(0, 0, 0, 0.125);
        ">
        <img
          class="img"
          src="../assets/risk.png"
          alt="Store Images"
        />&nbsp;&nbsp;&nbsp;
        <b-button variant="warning">ควรจดทะเบียนเพิ่มสาขา</b-button>
        <!-- <b-button>คุณอยู่ในระดับ “ความเสี่ยงสูง”</b-button> -->
        <br />
        <br />
        <h2>คำอธิบาย</h2>
        <h5>
          ถึงเวลาแล้วที่คุณต้องจดทะเบียนเพิ่มสาขา เนื่องจากกฎหมายกำหนดว่า
          หากกิจการมีสถานประกอบการ เพิ่มจากสำนักงานใหญ่ เช่น หน้าร้านขายสินค้า
          โรงงานผลิตสินค้า โกดังเก็บสินค้า ที่ก่อสร้างขึ้นเพื่อใช้ถาวร
          จะต้องขอจดทะเบียนเพิ่มสาขา และหลังจากจดทะเบียนเพิ่มสาขาแล้ว
          การจัดทำบัญชีจะมีความแตกต่างจากเดิมหรือไม่
          หรือควรจ้างสำนักงานบัญชีดีกว่ากัน สามารถศึกษาเพิ่มเติม<a
            href="https://inflowaccount.co.th/after-registering-company/"
            ><br />ที่นี่
          </a>
          <br />
        </h5>

        <h6 :class="maincolor">
          หากต้องการคำแนะนำวางแผนเรื่องภาษีสามารถทักแชทได้ที่
          <a
            :class="maincolor"
            href="https://lin.ee/911BqsX"
            target="_blank"
            rel="noopener noreferrer"
            >@ac567</a
          >
          หรือ
          <a :class="maincolor" href="tel:0910059903">โทร.091-005-9903</a>
          เพราะทุกปัญหาเรื่องภาษีใกล้ตัวคุณมากกว่าที่คุณรู้
          <a href="https://inflowaccount.co.th/company-registration-2/">
            <br />สามารถศึกษาเพิ่มเติมได้ที่นี่
          </a>
        </h6>
      </b-card>
      <b-row>
        <b-col class="text-center padding" cols="12">
          <router-link to="/">
            <b-button variant="warning">กลับ</b-button>&nbsp;&nbsp;&nbsp;
          </router-link>
          <router-link to="/">
            <b-button variant="warning">ทำแบบทดสอบอีกครั้ง</b-button>
          </router-link>
        </b-col>
      </b-row>
      <b-row> </b-row>
    </div>
  </div>
</template>
<script>
// import n from "./TheUnderLineImage.vue";
export default {
  components: {
    // TheUnderLineImage,
  },
  data() {
    return {
      maincolor: "maincolor",
      warning: "warning",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .header {
    background-color: rgb(0 0 0 / 79%);
    color: white;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
  }

  .logo {
    width: auto; 
    height: auto;
  }

  
  .main-content {
    margin-top: 70px; 
  }
.img-desktop{
  padding-bottom: 30px; 
  width: 60%;
}
@media (max-width: 768px) {
  .img-mobile{
  padding-bottom: 30px; 
  width: 100%;
}
}
.maincolor{
color:#EA6B0D;
}
.warning {
  color: #ea6b0d;
}

.padding {
  padding-bottom: 20px;
  padding-top: 20px;
}
.btn-warning {
  color: #ffffff;
  background-color: #ea6b0d;
  border-color: #ea6b0d;
}

.btn-warning:hover {
  color: #ea6b0d;
  background-color: #ffffff;
  border-color: #ea6b0d;
}
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #ffffff;
  background-color: #ea6b0d;
  border-color: #ea6b0d;
}
.btn-warning:focus,
.btn-warning.focus {
  color: #ffffff;
  background-color: #ea6b0d;
  border-color: #ea6b0d;
  box-shadow: 0 0 0 0.2rem rgba(239, 166, 20, 0.5);
}
.btn-secondary:hover {
  color: #5a6268;
  background-color: #ffffff;
  border-color: #545b62;
}
a:hover {
  color: #0056b3;
  text-decoration: none;
}
</style>
